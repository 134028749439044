<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-card class="mb-5" v-for="project in projectIds" v-bind:key="project">
          <v-card-title>
            {{ project }}
            <v-spacer></v-spacer>
          </v-card-title>
          <v-data-table :items="modelLogs[project]" :headers="headers">
            <template v-slot:item.timestamp="{ item }">
              {{ formatDateFromNow(item.timestamp.toDate()) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import moment from 'moment'
  export default {
    name: 'ModelLogs',

    data: () => ({
      headers: [
        { text: 'Project', value: 'projectId' },
        { text: 'ModelId', value: 'modelId' },
        { text: 'ModelName', value: 'modelName' },
        { text: 'ModelType', value: 'modelType' },
        { text: 'ModelReferenceId', value: 'modelReferenceId' },
        // { text: 'ModelReferenceName', value: 'modelReferenceName' },
        { text: 'Event', value: 'event' },
        // { text: 'UserEmail', value: 'userEmail' },
        { text: 'UserName', value: 'userName' },
        { text: 'ServerId', value: 'serverId' },
        { text: 'Timestamp', value: 'timestamp' }
      ],
      projectIds: ['ngapp5-dev', 'ngapp5-staging', 'ngapp5', 'ngapp5-ng']
    }),
    methods: {
      ...mapActions('models', [
        'getModelLogs'
      ]),
      formatDateFromNow (date) {
        return moment(date).fromNow()
      }
    },
    computed: {
      ...mapGetters('models', [
        'modelLogs'
      ])
    },
    mounted: function () {
      this.projectIds.forEach(project => {
        this.getModelLogs({
          projectId: project
        }).then(() => {
          console.log(`Got modelLogs for: ${project}`)
        })
      })
    }
  }
</script>
